export default function Tick() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      aria-hidden="true">
      <path
        fill="#287C34"
        fillRule="evenodd"
        d="M10.702.857a1.02 1.02 0 0 1 .424 1.395c-.086.177-.23.472-.426.727-.389.507-.687 1.08-1.027 1.73-.093.178-.189.361-.29.55-.166.31-.333.647-.508 1-.455.918-.96 1.937-1.608 2.811-.543.733-1.31 1.582-2.3 2.06-.167.081-.322.103-.4.111a1.574 1.574 0 0 1-.545-.037 1.178 1.178 0 0 1-.368-.163l-.014-.006-.03-.014a1.248 1.248 0 0 1-.398-.29 1.575 1.575 0 0 1-.127-.156 2.934 2.934 0 0 1-.588-.62 8.804 8.804 0 0 1-.243-.352 7.057 7.057 0 0 0-.129-.189c-.104-.144-.22-.298-.344-.46C1.337 8.367.806 7.666.46 6.881c-.223-.51.001-1.108.5-1.336a.983.983 0 0 1 1.31.51c.239.545.57.984.98 1.526.147.195.304.403.471.635.058.08.118.17.17.247l.021.032c.061.091.115.172.17.245a1.765 1.765 0 0 0 .168.199c.085.052.154.11.21.163.435-.298.848-.744 1.224-1.252.536-.722.912-1.482 1.33-2.328.194-.39.397-.8.628-1.233.077-.143.156-.295.238-.453.348-.667.751-1.442 1.259-2.104.062-.082.123-.2.232-.424a.982.982 0 0 1 1.33-.452Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
